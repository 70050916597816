import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTransactionStore } from '../../store/transactionStore';

export const Return = () => {
  const [session, setSession] = useState({
    id: null,
    status: null,
    amount: null,
  });
  const { fetchSession } = useTransactionStore();
  const location = useLocation();
  const navigate = useNavigate();

  const interval = setInterval(() => {
    if (session.status) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-16677566212/RhyxCMqIkcwZEITuvZA-',
        value: session.amount,
        currency: 'PLN',
        transaction_id: session.id,
      });
      clearInterval(interval);
    }
  }, 100);

  useEffect(() => {
    const fetchData = async () => {
      const queryString = new URLSearchParams(location.search);
      const sessionId = queryString.get('session_id');

      if (sessionId) {
        const session = await fetchSession(sessionId);
        setSession(session);
      }
    };

    fetchData();
  }, [fetchSession, location.search]);

  const handleContinue = () => {
    navigate('/');
  };

  if (session.status === 'open') {
    return <Navigate to="/checkout" />;
  }

  if (session.status === 'complete') {
    return (
      <section id="contact-us" className="contact-us section min-vh-60 fill-content">
        <div className="container ">
          <div className="contact-head wow fadeInUp" data-wow-delay=".4s">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-lg-6 col-12">
                <div className="form-main">
                  <div className="form-title">
                    <h2>Dziękujemy za zakup!</h2>
                  </div>
                  <div>
                    <div>Odkrycia pomyślnie zostały dodane do Państwa konta.</div>
                    <br /> Aby skorzystać z pakietu należy odszukać KW, kliknąć Zakup, a nastepnie przejść przez
                    formularz i zapłacić dostępnymi odkryciami.
                  </div>
                  <div className="mt-20 d-flex justify-content-center">
                    <button className="btn btn-primary" onClick={handleContinue}>
                      Dalej
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return null;
};
