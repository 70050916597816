import { AxiosError } from 'axios';
import { useSearchStore } from '../store/searchStore';
import axios from './axios';
import { BookPreviewData } from './previewApi';

export interface PurchaseResponse {
  status: string;
  data: BookPreviewData | null;
  error: string | null;
}

export const purchaseBook = async (bookId: number): Promise<PurchaseResponse> => {
  const { currentAddressSearch, currentLotSearch, currentSearchType, searchLotResults } = useSearchStore.getState();

  let reqBody = {};
  if (currentSearchType === 'address' && currentAddressSearch) {
    reqBody = {
      city: currentAddressSearch.city.city,
      commune: currentAddressSearch.city.commune,
      county: currentAddressSearch.city.county,
      province: currentAddressSearch.city.province,
    };
  } else if (currentSearchType === 'lot') {
    // const reqBody = {
    //   city: searchLotResults
    //   commune: currentLotSearch.city.commune,
    //   county: currentLotSearch.city.county,
    //   province: currentLotSearch.city.province,
    // };
  }
  try {
    const response = await axios.post(`/purchase/${bookId}`, reqBody);
    return { status: 'success', data: response.data, error: null };
  } catch (error) {
    console.error('Error purchasing book:', error);
    if (error instanceof AxiosError) {
      return { status: 'error', data: null, error: error.response?.data };
    } else {
      return { status: 'error', data: null, error: 'Coś poszło nie tak. Spróbuj ponownie.' };
    }
  }
};
