import { create } from 'zustand';
import axios from '../services/axios';
import { fetchSession } from '../services/paymentApi';

interface TransactionState {
  transactionId: string | null;
  setTransactionId: (transactionId: string) => void;
  fetchClientSecret: (priceId: string) => Promise<string>;
  fetchSession: (sessionId: string) => Promise<any>;
}

export const useTransactionStore = create<TransactionState>((set) => ({
  transactionId: null,
  setTransactionId: (transactionId) => set({ transactionId }),
  fetchClientSecret: async (priceId: string): Promise<string> => {
    const response = await axios.post('/transaction/create-checkout-session', {
      priceId,
    });
    return response.data.clientSecret;
  },
  fetchSession: async (sessionId: string): Promise<any> => {
    const response = await fetchSession(sessionId);
    return response;
  },
}));
