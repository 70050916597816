import React, { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTransactionStore } from '../../store/transactionStore';
import useAuthStore from '../../store/authStore';

interface PackageProps {
  price: string;
  name: string;
  lookupsCount: number;
  id: string;
  priceId: string;
  isBlurred?: boolean;
}

const Package: React.FC<PackageProps> = ({ price, priceId, lookupsCount, isBlurred = false }) => {
  const priceInZloty = Number(price) / 100;
  const regularPrice = 30;
  const premiumPrice = priceInZloty / lookupsCount;
  const priceDifference = Math.round(regularPrice - premiumPrice);
  const saving = Math.round((priceDifference / regularPrice) * 100);
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthStore();
  const { setTransactionId } = useTransactionStore();
  const { setLoginAdditionalInfo, setCustomRedirect } = useAuthStore();

  const onBuyClick = () => {
    if (isLoggedIn) {
      setTransactionId(priceId);
      navigate(`/checkout`);
    } else {
      setLoginAdditionalInfo('Aby zakupić pakiet, musisz być zalogowany.');
      setCustomRedirect('/#pricing');
      navigate('/logowanie');
    }
  };

  const getPackageText = (lookupsCount: number) => {
    if (lookupsCount == 1) {
      return (
        <>
          <li>Pojedyncze odkrycie księgi</li>
          <li>Do wykorzystania bezterminowo</li>
          <li>Podstawowa cena</li>
        </>
      );
    } else {
      return (
        <>
          <li>Pakiet {lookupsCount} odkryć ksiąg</li>
          <li>Do wykorzystania bezterminowo</li>
          <li>Oszczędność {saving}%!</li>
        </>
      );
    }
  };

  return (
    <div className="single-table wow fadeInUp" data-wow-delay=".2s">
      <div className={`table-head ${isBlurred ? 'blurred' : ''}`}>
        <div className="price">
          {lookupsCount == 1 ? <h2 className="amount">Single</h2> : <h2 className="amount">Pakiet {lookupsCount}</h2>}
        </div>
        <h4 className="title">{priceInZloty}zł</h4>
      </div>
      <ul className={`table-list ${isBlurred ? 'blurred' : ''}`}>{getPackageText(lookupsCount)}</ul>
      <div className={`button ${isBlurred ? 'blurred' : ''}`} onClick={!isBlurred ? onBuyClick : undefined}>
        <a className="btn">Zakup</a>
      </div>
    </div>
  );
};

export default Package;
