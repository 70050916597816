import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './ConsentModal.scss';

const ConsentModal: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const [adStorage, setAdStorage] = useState(true);
  const [adUserData, setAdUserData] = useState(true);
  const [adPersonalization, setAdPersonalization] = useState(true);
  const [analyticsStorage, setAnalyticsStorage] = useState(true);

  const getCookie = (key: string) => {
    const b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
  };

  const setCookie = (cname: string, cvalue: string, exdays: number) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  };

  const cookies_accepted = getCookie('google_cookies');

  const [isConsentModalOpen, setIsConsentModalOpen] = useState(cookies_accepted === '');

  if (cookies_accepted !== '') {
    return;
  }

  const closeConsentModal = () => {
    const consent = {
      ad_storage: adStorage ? 'accepted' : 'denied',
      ad_user_data: adUserData ? 'accepted' : 'denied',
      ad_personalization: adPersonalization ? 'accepted' : 'denied',
      analytics_storage: analyticsStorage ? 'accepted' : 'denied',
    };
    window.gtag('consent', 'update', consent);
    setIsConsentModalOpen(false);
    setCookie('google_cookies', JSON.stringify(consent), 365);
  };

  const deny = () => {
    const consent = {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
    };
    window.gtag('consent', 'update', consent);
    setCookie('google_cookies', JSON.stringify(consent), 365);
    setIsConsentModalOpen(false);
  };

  return (
    <Modal
      isOpen={isConsentModalOpen}
      onRequestClose={closeConsentModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
        },
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          maxWidth: '500px',
          backgroundColor: 'white',
          zIndex: 10000,
          padding: '0',
        },
      }}
    >
      <div className="col-12 g0">
        <div className="modal-header row justify-content-center px-0 py-3 g-0">Dbamy o Twoją prywatność</div>
        <div className="modal-body">
          <div>
            Przechowujemy lub uzyskujemy dostęp do informacji na urządzeniu, takich jak unikalne identyfikatory w
            plikach cookie w celu przetwarzania danych osobowych. Użytkownik może zaakceptować wybory lub zarządzać
            nimi, klikając poniżej lub w dowolnym momencie na stronie polityki prywatności. Te wybory będą sygnalizowane
            naszym partnerom i nie będą miały wpływu na dane przeglądania.
          </div>
          <div onClick={closeConsentModal} className="btn btn-primary" style={{ display: 'block' }}>
            Wyrażam zgodę
          </div>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <div className="pointer" onClick={deny}>
              Tylko niezbędne cookies
            </div>
            <div
              className="pointer"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              Dostosowuję swój wybór
            </div>
          </div>
          {expanded && (
            <div className="advanced-options">
              <div onClick={() => setAdStorage(!adStorage)}>
                <input type="checkbox" checked={adStorage} />
                &nbsp;Zgoda na przechowywanie danych (np. plików cookie) związanych z reklamami.
              </div>
              <div onClick={() => setAdUserData(!adUserData)}>
                <input type="checkbox" checked={adUserData} />
                &nbsp;Zgoda na wysyłanie do Google danych użytkownika związanych z reklamami.
              </div>
              <div onClick={() => setAdPersonalization(!adPersonalization)}>
                <input type="checkbox" checked={adPersonalization} />
                &nbsp;Zgody na reklamy spersonalizowane.
              </div>
              <div onClick={() => setAnalyticsStorage(!analyticsStorage)}>
                <input type="checkbox" checked={analyticsStorage} />
                &nbsp;Zgoda na przechowywanie danych (np. plików cookie) dotyczących statystyk, np. czasu trwania
                wizyty.
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ConsentModal;
