import React, { useState } from 'react';
import { SearchFlat as Flat, AddressResult } from '../../services/searchApi';
import FlatItem from './FlatItem';

import styles from './BookItem.module.scss';
import DetailsModal from '../details-modal/DetailsModal';
import usePreviewStore from '../../store/previewStore';
import useAuthStore from '../../store/authStore';
import { useNavigate } from 'react-router-dom';
import useOrderStore from '../../store/orderStore';
import { isBook } from './helpers';
import Tippy from '@tippyjs/react';

interface AddressBookItemProps {
  result: AddressResult;
  flat?: Flat;
}

const AddressBookItem: React.FC<AddressBookItemProps> = ({ result, flat }) => {
  const book = result?.book || flat?.book;
  const bookIdentifier = isBook(book) ? `${book.department}/${book.register}/${book.control_number}` : null;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLoggedIn, setCustomRedirect } = useAuthStore();
  const navigate = useNavigate();
  const { setCurrentOrder } = useOrderStore();

  const { previewData, fetchPreview } = usePreviewStore();
  const isBookDiscovered = !book?.register.includes('X');

  const hasFlats = result.flats.length !== 0;

  const handleDetailsClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (book) {
      await fetchPreview(book.id);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleExpand = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    if (target.tagName !== 'BUTTON' && !target.closest('button')) {
      setIsExpanded(!isExpanded);
    }
  };

  const onBuyBookClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (isBookDiscovered) {
      return null;
    }

    if (isBook(book)) {
      setCurrentOrder(book.id);

      if (!isLoggedIn) {
        setCustomRedirect('/zamowienie');
        return navigate('/logowanie');
      }

      return navigate('/zamowienie');
    }
  };

  return (
    <div className="single-item-grid">
      <div
        className={`row align-items-center g-0 ${isExpanded ? styles.expanded : ''}`}
        onClick={hasFlats ? toggleExpand : undefined}
        style={{ cursor: hasFlats ? 'pointer' : 'default' }}
      >
        <div className="col-lg-7 col-md-6 col-12">
          <div className="row g-0">
            <div className={`col-2 ${styles.chevron}`}>
              {hasFlats && <i className={`lni ${isExpanded ? 'lni-chevron-up' : 'lni-chevron-down'}`}></i>}
            </div>
            <div className="content col-10">
              <div className="row">
                <div className="col-12">
                  <h5 className="title">
                    {result.street} {result.number}
                  </h5>
                </div>
                <div className="col-6 col-md-9 col-lg-6">
                  <p className="location">
                    {result.city}, {result.zip_code}
                  </p>
                </div>
                <div className="col-12 col-md-3 col-lg-6">
                  <p className="location">
                    {result.area} m<sup>2</sup>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-6 col-12">
          {bookIdentifier ? (
            <div className="row mx-10 buttons">
              <div className="col-lg-6 col-md-6 col-6 mb-2 mb-md-0 d-flex justify-content-md-end justify-content-center">
                <button className="btn btn-secondary w-100 w-md-auto" onClick={handleDetailsClick}>
                  Szczegóły
                </button>
              </div>
              <div className="col-lg-6 col-md-6 col-6">
                <Tippy content="Zaloguj się aby zakupić" disabled={!!isLoggedIn}>
                  <button
                    className="btn btn-primary w-100 text-center justify-content-md-start justify-content-center"
                    onClick={onBuyBookClick}
                  >
                    {bookIdentifier}
                  </button>
                </Tippy>
              </div>
            </div>
          ) : (
            <div className="buttons d-flex justify-content-center">
              <p>Brak księgi</p>
            </div>
          )}
        </div>
        <div className="row gx-0 d-flex justify-content-center pb-2">
          {' '}
          {result.flats.length !== 0 && <>{result.flats.length} dostępnych lokali. Kliknij aby rozwinąć</>}
        </div>
      </div>
      <div className={`${styles.flatsList} g-0 ${isExpanded ? styles.expanded : ''}`}>
        {isExpanded && (
          <>
            <div className={`col-12 row g-0 ${styles.flatsHeader}`}>
              <div className="col-lg-2 col-md-2 col-2 pl-10">Numer lokalu</div>
              <div className="col-lg-3 col-md-3 col-2">Powierzchnia</div>
              <div className="col-lg-7 col-md-7 col-8 text-end px-4">Numer ksiegi</div>
            </div>
            <div className={`${styles.flatsContent}`}>
              {result.flats.map((flat, index) => (
                <FlatItem key={index} flat={flat || {}} />
              ))}
            </div>
          </>
        )}
      </div>

      {previewData && <DetailsModal isOpen={isModalOpen} closeModal={closeModal} previewData={previewData} />}
    </div>
  );
};

export default AddressBookItem;
